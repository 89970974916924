    /* webcam  */
    .webcam-container
    {
    display: flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    margin:0 auto;
    }
   .webcam-container .webcam-btn
    {
    margin-top: 10px;
    margin-bottom: 20px;
    width:38%;
    }
    .webcam{
      width: 100%;
      height: 5%;
      }

  
.cont {
  max-height: 55vh;
  overflow: scroll;
}

.welcomeTest {
  font-size: 16px;
}
.formContainer{
  width: 100% !important;
}

.nextButton{
  box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.4);
  animation:brath 8s  linear infinite;
}
@keyframes brath{
  0%{transform: scale(.9);}
  25%{transform: scale(1);}
  60%{transform: scale(.9);}
  100%{transform: scale(1);}
}
@media  ( min-width: 576px) {
 
}

@media ( min-width: 768px) {
  
}

@media ( min-width: 992px) {
  
}

@media ( min-width: 1200px)  {
  .welcomeTest {
    font-size: 20px;
  }
}